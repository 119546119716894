<template>
  <a-modal
    class="reference-edit-modal"
    title="Редактировать запись"
    :visible="modalVisible"
    :closable="true"
    :confirm-loading="!(!loading && !confirmLoading)"
    @ok="update"
    @cancel="resetForm"
  >
    <a-form :model="reference" @keyup.enter="update">
      <div class="col-md-12">
        <a-upload
          name="icon"
          list-type="picture-card"
          class="logo-uploader"
          :show-upload-list="false"
          :headers="getHeaders()"
          :action="getUrl"
          :before-upload="beforeUpload"
          @change="uploadImage"
        >
          <img v-if="iconUrl" :src="iconUrl" alt="avatar" />
          <div v-else>
            <loading-outlined v-if="loading"></loading-outlined>
            <PlusOutlined v-else></PlusOutlined>
            <div class="ant-upload-text">Логотип</div>
          </div>
        </a-upload>
        <div class="mb-4 text-center" v-if="iconUrl">
          <a-button @click="removeImage" type="link" danger><CloseCircleOutlined />Удалить фотографию</a-button>
        </div>
        <a-form-item label="Название записи">
          <a-input
            ref="referenceName"
            placeholder="Введите название записи"
            v-model:value="reference.title"
          />
        </a-form-item>
        <a-form-item label="Переводы" v-if="reference.locales.length">
          <div class="container pb-3" v-for="(model, key) in reference.locales" :key="key">
            <div class="row">
              <div class="col-md-8">
                <div class="row">
                  <a-input placeholder="Введите название" v-model:value="model.title"></a-input>
                </div>
              </div>
              <div class="col-md-3">
                <div class="row">
                  <a-select class="pl-3" v-model:value="model.locale">
                    <a-select-option v-for="(locale, lKey) in locales" :key="lKey" :value="locale.short">{{ locale.name }}</a-select-option>
                  </a-select>
                </div>
              </div>
              <div class="col-md-1">
                <div class="row">
                  <a-button @click="removeLocale(key)" type="link" danger><MinusCircleOutlined /></a-button>
                </div>
              </div>
            </div>
          </div>
        </a-form-item>
        <a-button
          @click="addLocale"
          shape="round"
          type="link"
          class="px-0"
        >
          <template #icon>
            <PlusCircleOutlined />
          </template>Добавить перевод</a-button>
      </div>
    </a-form>
  </a-modal>
</template>

<script setup>
import {ref, reactive, toRaw, onMounted, nextTick} from 'vue';
import apiClient from "@/services/axios";
import Reference from "@/models/Reference";
import {
  PlusCircleOutlined,
  MinusCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons-vue";
import {getHeaders} from "@/helpers/header";
import {getBase64} from "@/helpers/base64";
import {message} from "ant-design-vue";

const props = defineProps({
  locales: {
    type: Array,
    default: null,
  },
  referenceId: {
    type: Number,
    default: 0,
  },
})

const emit = defineEmits(['hide-modal', 'reload']);

let iconUrl = ref(''),
  loading = ref(true),
  confirmLoading = ref(false),
  modalVisible = ref(true),
  referenceName = ref("referenceName"),
  initialReference = new Reference({}),
  reference = reactive(initialReference);

const
  addLocale = () => {
    reference.locales.push({
      id: null,
      title: null,
      locale: props.locales.find(() => true).short,
    })
  },
  uploadImage = info => {
    iconUrl.value = ''
    if (info.file.status === 'uploading') {
      loading.value = true;
      return;
    }

    if (info.file.status === 'done') {
      reference.icon = info.file.response.data
      getBase64(info.file.originFileObj, base64Url => {
        iconUrl.value = base64Url;
        loading.value = false;
      });
    }

    if (info.file.status === 'error') {
      loading.value = false;
      message.error('upload error');
    }
  },
  removeImage = () => {
    iconUrl.value = ''
    reference.icon = ''

    confirmLoading.value = true;
    apiClient
      .put(`/filter/update?id=${props.referenceId}`, { icon: '' })
      .then(response => {
        if (response) {
          confirmLoading.value = false;
        }
      }).catch(err => {
      confirmLoading.value = false;
    }).finally(() => {
      confirmLoading.value = false;
    })
  },
  beforeUpload = file => {
    const isJpgOrPngOrSvg = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';

    if (!isJpgOrPngOrSvg) {
      message.error('You can only upload JPG, PNG or SVG file!');
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }

    return isJpgOrPngOrSvg && isLt2M;
  },
  getUrl = () => {
    return process.env.VUE_APP_BACKEND_URL + '/cabinet/filter/upload-image'
  },
  removeLocale = (key) => {
    reference.locales.splice(key, 1)
  },
  resetForm = () => {
    Object.assign(reference, new Reference({}));
    modalVisible.value = false;
    emit('hide-modal')
  },
  update = () => {
    confirmLoading.value = true;

    let model = toRaw(reference)

    return apiClient.put(`/filter/update?id=${props.referenceId}`, model).then(response => {
      if (response) {
        resetForm()
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      confirmLoading.value = false;
      modalVisible.value = false;
      emit('reload')
      emit('hide-modal')
    })
  };

apiClient.get(`/filter/view?id=${props.referenceId}`).then(response => {
  if (response) {
    iconUrl.value = response.data.data.icon
    let model = new Reference(response.data.data)
    Object.assign(reference, model)
  }
}).catch(() => {
  // console.log(err)
}).finally(() => {
  loading.value = false
})

onMounted(() => nextTick(() => referenceName.value?.focus()))
</script>

<style scoped>
@import "./edit.css";
</style>
